const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://ubrflash.dvgeo.app' : 'http://192.168.100.106:3033',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://ubrflash.dvgeo.app' : 'http://192.168.100.106:3033',
    api: 'api/',
    apiSocket: 'ubrflash::1.2',
    nameDir: 'ubrflash',
    package: 'app.dvgeo.ubrflash.web',
    version: '1.0.2',
    googleAPIKey: 'AIzaSyBpYIyWJX91mZNazoQc3Rg8BNp2DdDmzHQ',
    appName: 'Móvil Ya',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#00B2F0',
    colorDark: '#000000',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.ubrflash.passenger',
    playStoreDriverId: 'app.dvgeo.ubrflash.driver',
    appStorePassengerId: '6459619067',
    appStoreDriverId: '6459618904',
    email: "Ubrflash@gmail.com",
};
export default config;
